<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>Destinations Ranking</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-switch
                v-model="allCustomers"
                label="All Customer"
                dense
                @click="clearData()"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                prepend-inner-icon="mdi-account"
                v-model="customer"
                :items="customers"
                item-text="clastname"
                item-value="custledgkey"
                label="Customer"
                persistent-hint
                return-object
                single-line
                dense
                outlined
                :disabled="allCustomers"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getDestinations()"
                :disabled="!valid"
                >Generate</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            color="btn-large btn-block primary white--text"
            class="mr-2"
            @click="downloadXLS"
            :disabled="!valid"
            >XLS</v-btn
          >
          <vue-json-to-csv
            :json-data="destinations"
            :csv-title="`AEJ-DTR-${this.startDate}-${this.endDate}`"
          >
            <v-btn color="btn-large btn-block primary white--text"> CSV </v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="destinations"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      allCustomers: true,
      headers: [
        {
          text: "Destination City/Airport",
          align: "start",
          value: "destination",
        },
        { text: "Rank", align: "end", value: "rank" },
        { text: "Percentage", align: "end", value: "percentage" },
      ],

      destinations: [],
      customers: [],
      customer: {},
      total: 0,
      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
    };
  },

  mounted() {
    this.getCustomers();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },

    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    clearData() {
      this.destinations = [];
      this.customer = {};
    },

    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else if (
        !this.allCustomers &&
        Object.keys(this.customer).length === 0
      ) {
        this.$toasted.global.optimus_error({
          message: "Select a customer from the drop down list.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD-MM-YYYY");
    },

    async getDestinations() {
      if (this.validateData()) {
        this.destinations = [];
        try {
          let response = "";
          this.loading = true;
          if (!this.allCustomers) {
            response = await api.post("/aejmis/api/destinationranks", {
              startdate: this.startDate,
              enddate: this.endDate,
              ledgerkey: this.customer.custledgkey,
            });
          } else {
            response = await api.post("/aejmis/api/alldestinationranks", {
              startdate: this.startDate,
              enddate: this.endDate,
            });
          }
          const rawdata = response.data;
          rawdata.forEach((item) => {
            this.total = this.total + item.rank;
          });
          rawdata.map(({ destination, rank }) => {
            this.destinations.push({
              destination: destination,
              rank: rank,
              percentage: ((rank / this.total) * 100).toFixed(2),
            });
          });
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    async getCustomers() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/customers");
        this.customers = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async downloadXLS() {
      const ExcelJS = require("exceljs");
      const fileSaver = require("file-saver");

      // Create workbook & add worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Airline Ranking");

      // add column headers
      worksheet.columns = [
        { header: "Destination", key: "destination", width: 25 },
        {
          header: "Rank",
          key: "rank",
          style: { numFmt: "#,##0" },
          width: 15,
        },
        {
          header: "Percentage",
          key: "percentage",
          style: { numFmt: "#,##0.00" },
          width: 15,
        },
      ];

      // make header bold
      worksheet.getRow(1).font = { name: "Roboto", bold: true };

      // style columns
      worksheet.columns[0].alignment = {
        vertical: "middle",
        horizontal: "left",
      };

      worksheet.columns[1].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[2].alignment = {
        vertical: "middle",
        horizontal: "right",
      };

      // Add rows using both the above of rows
      if (this.destinations.length > 0) {
        let rows = [];
        this.destinations.forEach((carrier) => {
          rows.push({
            destination: carrier.destination,
            rank: carrier.rank,
            percentage: carrier.percentage,
          });
        });

        worksheet.addRows(rows);

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], { type: "application/octet-stream" });
          fileSaver.saveAs(
            blob,
            `AEJ-DTR-${this.startDate}-${this.endDate}.xlsx`
          );
        });
      }
    },
  },
};
</script>
